
import { defineComponent, watch } from 'vue';
import ActionSheet from 'vant/lib/action-sheet';
import { radarLog } from '~/common/radar';

export default defineComponent({
    components: {
        vanActionSheet: ActionSheet,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['confirm', 'cancel'],
    setup(props, { emit }) {
        const onConfirm = () => {
            radarLog({
                name: 'PWA_WEB_TO_APP_CONFIRM',
                event_type: 'show',
                category: 'KWAI_SHARE',
                message: 'PWA_WEB_TO_APP_CONFIRM',
                src: window.location.href,
            });
            emit('confirm');
        };

        const onCancel = () => {
            radarLog({
                name: 'PWA_WEB_TO_APP_CANCEL',
                event_type: 'show',
                category: 'KWAI_SHARE',
                message: 'PWA_WEB_TO_APP_CANCEL',
                src: window.location.href,
            });
            emit('cancel');
        };

        watch(
            () => props.show,
            newValue => {
                if (newValue) {
                    radarLog({
                        name: 'PWA_WEB_TO_APP_SHOW',
                        event_type: 'show',
                        category: 'KWAI_SHARE',
                        message: 'PWA_WEB_TO_APP_SHOW',
                        src: window.location.href,
                    });
                }
            }
        );

        return {
            onConfirm,
            onCancel,
        };
    },
});
